.tab {
  float: left;
  /* border: 1px solid #ccc; */
  background-color: transparent;
  width: 30%;
  min-height: 470px;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  /* background-color: var(--primary); */
  color: var(--orange);
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: var(--primary);
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 16px;
  /* border: 1px solid #ccc; */
  width: 70%;
  border-left: none;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
  min-height: 470px;
}

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.drag-media {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

p {
  margin: 0;
}

.upload__box {
  padding: 10px;
  min-height: 300px;
  border: 3px dashed black;
  text-align: center;
  background: lightgrey;
  padding: 3em;
  margin: auto;
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload__btn {
  /* display: inline-block;
  font-weight: 600;
  color: #fff;
  text-align: center;
  min-width: 116px;
  padding: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid;
  background-color: #4045ba;
  border-color: #4045ba;
  border-radius: 10px;
  line-height: 26px;
  font-size: 14px; */
  display: inline-block;
  padding: 0.5em 1em;
  background: black;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid darkslateblue;
  color: #fff;
  transition: 0.4s;
}

/* .upload__btn:hover {
  background-color: unset;
  color: #4045ba;
  transition: all 0.3s ease;
} */
.upload__btn-box {
  margin-bottom: 10px;
}

.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.upload__img-box {
  width: 200px;
  padding: 0 10px;
  margin-bottom: 12px;
}

.upload__img-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}

.upload__img-icon:after {
  font-size: 14px;
  color: white;
}

.upload__img-close {
  top: 10px;
  right: 10px;
}

.upload__img-close:after {
  content: "\2716";
}

.upload__img-cover {
  top: 10px;
  right: 40px;
}

.upload__img-cover:after {
  content: "\f02b";
}

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}

.details-img-display-box {
  display: flex;
  gap: 20px;
}

.cover_image_add {
  color: var(--yellow);
  z-index: 100;
  position: absolute;
  top: 10px;
  font-size: 16px;
}