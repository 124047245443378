@media (min-width: 576px) {
 
}

.dataTables_wrapper .dataTable a.no_sort {
  color: #3699ff !important;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

#kt_footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

/* .swal2-icon {
  margin: 10px auto 0 auto;
} */
